import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import {BLOCKS, MARKS,INLINES} from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Container } from "react-bootstrap";
import Updates from "../components/Updates"
import CtaContact from "../components/CtaContact"
import CtaMeet from '../components/CtaMeet'
import Quote from '../components/Quote'
import LeftImg from "../components/LeftImg";
import RightImg from "../components/RightImg";
import TwoImageBlocks from "../components/TwoImageBlocks";
import TwoTextBlocks from "../components/TwoTextBlocks";
import ShareButtons from "../components/sharebuttons";
import CtaLead2 from "../components/CtaLead2";
import Tweet from "../components/Tweet";
import Facebook from "../components/Facebook";
import { Seo } from "../components/Seo";
import { useLocation } from "@reach/router"

const ComponentName = ({ 
    data:{updates:
        {upTitle,
        upImage,
        upDescription, 
        // author, 
        upDate,
        content:json}} }) => {
    
    const cnt =json;
    // console.log("TESTTTTT");
    // console.log(json);
    

    const slugComponentMap = {
        "contact-us": CtaMeet,
        "quote": Quote,
        "left-img" : LeftImg,
        "right-img": RightImg,
        "two-image-blocks": TwoImageBlocks,
        "two-text-blocks" :TwoTextBlocks,
        "cta-lead-2" : CtaLead2,
        "tweet" : Tweet,
        "facebook" : Facebook,
    };
    const pathLocation= useLocation();
    const url= pathLocation.href;
    // const url= typeof window !== "undefined" ? window.location.href : "";
    //const url= "https://sogody.com";
    const defaultInline = (type, node) => <span>type: {type} id: {node.data.target.sys.id}</span>;

    const options={
        renderNode:{
            
        [BLOCKS.EMBEDDED_ENTRY]:(node, children) => {
            const Comp = slugComponentMap[node.data.target.fields.slug['en-US']];
            console.log(slugComponentMap[node.data.target.fields.slug['en-US']]);
            const fields = node.data.target.fields;

            const props = Object.keys(fields).map(f => ({[f]: fields[f]['en-US']})).reduce((curr, acc) => ({...curr, ...acc}), {});
            return <Comp {...props} />
        },
        [INLINES.ASSET_HYPERLINK]: node => defaultInline(INLINES.ASSET_HYPERLINK, node),
        [INLINES.ENTRY_HYPERLINK]: node => defaultInline(INLINES.ENTRY_HYPERLINK, node),
        [INLINES.HYPERLINK]: (node) => {
				if (node.data.uri.indexOf('youtube.com') !== -1) {
					return (
						<div >
							<iframe
								id="ytplayer"
								src={node.data.uri}
								type="text/html"
								width="640"
								height="360"
                                frameBorder="0"
                                target="_blank"
                                rel="noreferrer"
								allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture ; fullscreen"
							/>
						</div>
					);
				} else
					return (
						<a
							href={node.data.uri}
							target="_blank"
						>
							{node.content[0].value}
						</a>
					);
			} ,

        [BLOCKS.HEADING_1]:(node, children) =>
        (<h1 className="heading1">{children}</h1>),

        [BLOCKS.HEADING_2]:(node, children) =>
        (<h2 className="heading2">{children}</h2>),

        [BLOCKS.HEADING_3]:(node, children) =>
        (<h3 className="heading3">{children}</h3>),

        [BLOCKS.HEADING_4]:(node, children) =>
        (<h4 className="heading4">{children}</h4>),

        [BLOCKS.HEADING_5]:(node, children) =>
        (<h5 className="heading5">{children}</h5>),

        [BLOCKS.HEADING_6]:(node, children) =>
        (<h6 className="heading6">{children}</h6>),

        [BLOCKS.UL_LIST]:(node, children) =>
        (<ul className="ul-list">{children}</ul>),

        [BLOCKS.OL_LIST]:(node, children) =>
        (<ol className="ol-list">{children}</ol>),
        
        [BLOCKS.EMBEDDED_ASSET]:(node, children) =>
        (<img className="update-cnt-image" alt="update img" src={`https:${node.data.target.fields.file["en-US"].url}`}/>),
        [BLOCKS.PARAGRAPH]:(node, children) =>
        (<p className="up-paragraph">{children}</p>),
        },
        [BLOCKS.QUOTE]: (node, children) => <blockquote>{children(node.content)}</blockquote>,
        [BLOCKS.HR]: () => <hr />,

        renderMark: {
            [MARKS.CODE]: code => <code>{code}</code>,
            // [MARKS.BOLD]: text => <b>{`${text}`} </b>,
            // [MARKS.UNDERLINE]: text => <u>{text}</u>,
            [MARKS.ITALIC]: text => <i>{text}</i>,
        },
    }

    return <div  className="single-sog-up">
    <Layout>
        <Seo 
            title={upTitle} 
            description={upDescription.upDescription} 
            image={"https:" + upImage.file.url}
            url={url}
        /> 
        <div className="banner-update-template">
            <Container className="up-container">
            {upImage && <img  src={upImage.file.url} alt="Logo" style={{display:"none"}}/> }
                <p className="createdAt">{upDate}</p>
                <h1 className="title">{upTitle}</h1>
                <hr className="horizontal"></hr>
                {/* <p className="by">By <span className="author">{author}</span></p> */}
            </Container>
        </div>
        <div className="cs-b">
            <Container className="up-container"> 
                {/* {upImage && */}
                <div className="cs-logo">
                    <img  src={upImage.file.url} alt="Logo"/> 
                </div>
                 {/* } */}
            </Container>
        </div>
        <div className="single-up-content"> 
        <Container className="up-container"> 
            <div className="single-update">
                {documentToReactComponents(cnt.json, options)
                    && (
                        <p>{documentToReactComponents(cnt.json, options)}</p>
                    )
                }
            </div>
            <CtaContact/>
            <ShareButtons url={url ? url : " "} title={upTitle} summary={upTitle} body={upTitle + "\n\n" + " Visit:" + url+ "\n"}/>
        </Container>
        </div>
        <Updates/>
    </Layout></div>
}

export const query = graphql`
query GetSingleUpdate($slug:String){
    updates:contentfulUpdates(upSlug: {eq: $slug}, node_locale: {eq: "en-US"}) {
        upTitle
        upSlug
        author
        upDescription {
            upDescription
        }
        upImage{
            file{
                url
            }
        }
        upDate(formatString: "D MMMM YYYY")
        content{
            json
        }
    }
}
`

export default ComponentName

