import {graphql, useStaticQuery } from 'gatsby';
import React from 'react'
import {Button, Col, Figure, Row } from 'react-bootstrap'

export default function() {
    const data = useStaticQuery(query);
    const {allContentfulCtaCeoCalendly2:{
        nodes:ctaLead
    }} = data

    return (
        <div className="cta-lead-2">
            {/* <Container>  */}
                <div className="cta-lead-container"> 
                    {ctaLead.map((ctaLead)=>{
                        return <>
                            <Row>
                                <Col xs="12" sm="12" md="12" lg="12" className="cta-text">
                                    <h1 className="cta-title">{ctaLead.title}</h1>
                                </Col>
                                <Col  xs="12" sm="12" md="12" lg="12" className="cta-img">
                                    {/* <Row style={{width:"100%"}}> */}
                                        <div className="lead-img">
                                            <img className="cta-lead-img" src={ctaLead.authorImg.fluid.src} alt="Cta img"/>
                                        </div>
                                        <div className="lead-author">
                                            <p className="cta-author">{ctaLead.author}</p>
                                            <p className="cta-author-position">{ctaLead.authorPosition}</p>
                                            <Button className="cta-calendly cta-lead-calendly-2 secondary-btn-s" href="https://calendly.com/lorikmullaademi/15-minute-meeting" target="_blank">
                                                Calendly   <Figure className="calendly-img">
                                                    <Figure.Image   src={require('../assets/images/calendly.png')}  />
                                                </Figure>
                                                </Button>
                                        </div>
                                    {/* </Row> */}
                                    
                                </Col>
                            </Row>
                            
                        </>
                    })}
                </div>
            {/* </Container> */}
        </div>
    )
}

export const query = graphql`
{
    allContentfulCtaCeoCalendly2(filter: {node_locale: {eq: "en-US"}}, limit: 1) {
        nodes {
            author
            title
            btnText
            authorPosition
            authorImg {
                fluid {
                    src
                }
            }
        }
    }
}
`
