import {graphql, useStaticQuery } from 'gatsby';
import React from 'react'
import { Button, Col, Figure, Row } from 'react-bootstrap'
import ContactUs from './ContactUs';

export default function() {
    const data = useStaticQuery(query);
    const {allContentfulCtaReachoutArticleAuthor:{
        nodes:ctaContact
    }} = data
const [modalShow, setModalShow] = React.useState(false);

    return (
        <div className="cta-contact">
            {/* <Container>  */}
                    {ctaContact.map((ctaContact)=>{
                        return <a className="contact-anchor"  onClick={() => setModalShow(true)} >
                        <div className="cta-contact-container"> 
                            <Row>
                                <Col xs={12} md="12" lg="12" className="cta-text">
                                    <p className="cta-title">{ctaContact.title.title}</p>
                                    <p className="cta-subtitle">{ctaContact.subtitle.subtitle}</p>
                                    <Button className="contact-btn-s"   onClick={() => setModalShow(true)} >{ctaContact.btnText} 
                                        <Figure className="contact-img">
                                            <Figure.Image   src={require('../assets/images/next.svg')}  />
                                        </Figure>
                                    </Button>
                                </Col>   
                            </Row> 
                            </div> 
                        </a> 
                    })} 
                <ContactUs show={modalShow} onHide={() => setModalShow(false)} />
            {/* </Container> */}
        </div>
    )
}

export const query = graphql`
{
    allContentfulCtaReachoutArticleAuthor(filter: {node_locale: {eq: "en-US"}}, limit: 1) {
    nodes {
        btnText
        subtitle {
            subtitle
        }
            title {
            title
            }
        }
    }
}
`
